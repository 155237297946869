import queryString from 'query-string';
import { BaseService } from '../base.service';
import { ResponseWrapper, ErrorWrapper } from '../util';

export class PersonService extends BaseService {
    static get entity() {
        return 'person';
    }

    static async getAll({ currentPage, rowsPerPage, ...query }) {
        currentPage = currentPage || 1;
        rowsPerPage = rowsPerPage || 35;
        try {
            let { data, ...rest } = await this.request().get(
                `/v1/person/getList?${queryString.stringify({
                    //MAX-1129
                    //limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
                    sort: 'lastName,firstName',
                    ...query,
                })}`
            );
            return new ResponseWrapper({ ...rest, data: data.data }, { message: rest.message });
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getListOfPersonsForDropDown(params) {
        try {
            let response = await this.request().get(
                `${this.entity}s/dropdown?${queryString.stringify({
                    limit: '0,10',
                    sort: '-personIsActive,lastName',
                    ...params,
                })}`
            );
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getPersonByID(id) {
        try {
            let response = await this.request().get(`${this.entity}/${id}`);
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async SearchListOfPersons(searchTerm) {
        try {
            const response = await this.request().get(
                `${this.entity}s/dropdown?textFilter%5BfirstName%2ClastName%2CdisplayTitle%2CremaxPersonID%2CregionName%2CprimaryOfficeName%2CuniquePersonID%5D=${searchTerm}&limit=0%2C200&sort=-personIsActive%2ClastName`
            );
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getPersonHistory(id) {
        try {
            const response = await this.request().get(`${this.entity}/${id}/history`);
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getPersonLicenseNumbers(person_id) {
        try {
            let response = await this.request().get(`${this.entity}/${person_id}/licenseNumbers`);
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getPersonDesignations(person_id) {
        try {
            let response = await this.request().get(`${this.entity}/${person_id}/designation`);
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getContactInformationEntries(person_id) {
        try {
            let response = await this.request().get(`${this.entity}/${person_id}/contactInformationEntry`);
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getHistory(person_id) {
        try {
            let response = await this.request().get(`${this.entity}/${person_id}/editHistory`);
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getOffices(person_id) {
        try {
            let response = await this.request().get(`${this.entity}/${person_id}/offices`);
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getTitles(person_id, query = {}) {
        try {
            let response = await this.request().get(
                `${this.entity}/${person_id}/history?${queryString.stringify({
                    only_active: 1,
                    ...query,
                })}`
            );
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getTeams(person_id, query = {}) {
        try {
            let response = await this.request().get(
                `${this.entity}/${person_id}/teams?${queryString.stringify({
                    only_active: 0,
                    ...query,
                })}`
            );
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getSystemAccessRelations(person_id, query = {}) {
        try {
            let response = await this.request().get(
                `permission/person/${person_id}/relations?${queryString.stringify({
                    ...query,
                })}`
            );
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getTerminationReasons() {
        try {
            let response = await this.request().get('agentTerminationReason');
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async terminatePerson(person_id, payload) {
        try {
            let response = await this.request().post(`/person/${person_id}/terminatePerson`, payload);
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async createAdminTitle(person_id, payload) {
        try {
            let response = await this.request().post(`/person/${person_id}/createAdminTitle`, payload);
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async updatePersonTitle(id, payload) {
        try {
            let response = await this.request().put(`personPrimaryTitleHistory/${id}`, payload);
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getPerformanceData(person_id) {
        try {
            let response = await this.request().get(`${this.entity}/${person_id}/awards`);
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getNotes(person_id) {
        try {
            let response = await this.request().get(`${this.entity}/${person_id}/note`);
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getRolesDropdown(query = {}) {
        try {
            let response = await this.request().get(
                `roles?${queryString.stringify({
                    sort: '-remaxEntityCategoryID,roleLevel',
                })}`
            );
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async getRegionsDropdown(query = {}) {
        try {
            let response = await this.request().get(
                `regions?${queryString.stringify({
                    sort: 'name',
                })}`
            );
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async insertNote(entity, id) {
        try {
            const response = await this.request().post(`${this.entity}/${id}/note`, entity);
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async updateNote(entity, id) {
        try {
            const response = await this.request().put(`note/${id}`, entity);
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async deleteNote(id) {
        try {
            const response = await this.request().delete(`personNote/${id}`);
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async deletePerson(person_id) {
        try {
            const response = await this.request().delete(`person/${person_id}`);
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async teamOffices(person_id, query = {}) {
        try {
            const response = await this.request().get(
                `person/${person_id}/teamOffices?${queryString.stringify({
                    sort: 'entryValue',
                    ...query,
                })}`
            );
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async teams(person_id, query = {}) {
        try {
            const response = await this.request().get(
                `teams/${person_id}/office?${queryString.stringify({
                    sort: 'name',
                    ...query,
                })}`
            );
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async saveTeam(payload) {
        try {
            const response = await this.request().post(`teams`, payload);
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async agentHasTeam(payload) {
        try {
            const response = await this.request().post(`person/${payload.personID}/teams`, payload);
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async insertPerson(payload) {
        try {
            const response = await this.request().post('persons', payload);
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async updatePerson(person_id, payload) {
        try {
            const response = await this.request().put(`person/${person_id}`, payload);
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async loadPersonData(person_id) {
        const data = {
            person: null,
            designation: null,
            language: null,
            emailCommunicationTag: null,
            primaryTitleHistories: null,
            contactInformationEntry: null,
            licenseNumbers: null,
            ranks: null,
            statistics: null,
        };
        const errors = {};
        let response = null;

        try {
            const person = await this.request().get(`person/${person_id}?${queryString.stringify({})}`);
            response = person;
            data.person = person?.data?.result;
        } catch (e) {
            errors.person = e;
        }

        try {
            const designation = await this.request().get(`person/${person_id}/designation?${queryString.stringify({})}`);
            data.designation = designation?.data?.result;
        } catch (e) {
            errors.designation = e;
        }

        try {
            const language = await this.request().get(`person/${person_id}/language?${queryString.stringify({})}`);
            data.language = language?.data?.result;
        } catch (e) {
            errors.language = e;
        }

        try {
            const emailCommunicationTag = await this.request().get(
                `person/${person_id}/emailCommunicationTag?${queryString.stringify({})}`
            );
            data.emailCommunicationTag = emailCommunicationTag?.data?.result;
        } catch (e) {
            errors.emailCommunicationTag = e;
        }

        try {
            const primaryTitleHistories = await this.request().get(
                `person/${person_id}/primaryTitleHistories?${queryString.stringify({
                    sort: 'validFrom',
                })}`
            );
            data.primaryTitleHistories = primaryTitleHistories?.data?.result;
        } catch (e) {
            errors.primaryTitleHistories = e;
        }

        try {
            const contactInformationEntry = await this.request().get(
                `person/${person_id}/contactInformationEntry?${queryString.stringify({})}`
            );
            data.contactInformationEntry = contactInformationEntry?.data?.result;
        } catch (e) {
            errors.contactInformationEntry = e;
        }

        try {
            const licenseNumbers = await this.request().get(
                `person/${person_id}/licenseNumbers?${queryString.stringify({
                    sort: 'createdAt',
                })}`
            );
            data.licenseNumbers = licenseNumbers?.data?.result;
        } catch (e) {
            errors.licenseNumbers = e;
        }

        try {
            const ranks = await this.request().get(`person/${person_id}/ranks?${queryString.stringify({})}`);
            data.ranks = ranks?.data?.result;
        } catch (e) {
            errors.ranks = e;
        }

        try {
            const statistics = await this.request().get(`person/${person_id}/statistics?${queryString.stringify({})}`);
            data.statistics = statistics?.data?.result;
        } catch (e) {
            errors.statistics = e;
        }

        console.log(errors);

        return new ResponseWrapper(response, { result: { data, errors } });
    }

    static async deleteAndInsertEmailCommunicationTags(payload) {
        try {
            const response = await this.request().post(`${this.entity}/deleteAndInsertEmailCommunicationTags`, payload);
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async updateUser(payload) {
        try {
            console.log(payload);
            const response = await this.request().put(`/login/:ID`, payload);
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    static async updateOtherUserPassword(payload) {
        try {
            //make a call to the new backend with the payload containing:
            //user: { username: 'abc', password: 'def', personID: 123},
            //adminUser: { username: 'abc', password: 'def', personID: 123}

            const response = await this.request().post(`/v1/person/updateOtherUserPassword`, payload);
            return new ResponseWrapper(response, response.data.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }

    //this is useful in the case where we want to search for a person in a specific region, typically used in dropdowns
    static async searchPersonsInRegion(regionId, page, limit, searchValue) {
        try {
            //make a call to the existing backend to search for a person that exists in a region
            //regionId : number
            //page : page number
            //limit : number of records per page
            //searchTerm : search term to search for a person

            //make get request to 'persons' endpoint, passing the officeRegionId to only look for persons in that specific region
            const response = await this.get('persons', {
                'filter[regionID][in]': regionId,
                isActive: '1',
                limit: `${(page - 1) * limit},${limit}`,
                sort: '-personIsActive,lastName',
                'textFilter[firstName,lastName,displayTitle,remaxPersonID,regionName,primaryOfficeName,uniquePersonID]':
                    searchValue || undefined,
            });

            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.statusText;
            throw new ErrorWrapper(error, message);
        }
    }
}

import moment from "moment";
import XLSX from "sheetjs-style";
import xlsx from "sheetjs-style";
import { getReportData } from "@/components/editor/reports/helpers";
import { BaseService } from "@/services/base.service";
import { month } from "@formkit/inputs";

export async function exportToExcel(
    reportTemplate,
    reportData,
    selectedColumns,
    largeDataSet = true
) {
    const timestamp_start = new Date().getTime();
    const currency = reportTemplate.templateData.currency;
    let reportDataModel;
    let reportingCurrencyIso;
    const exportColumns = selectedColumns;
    const currencyFields = ["GCI", "Volumes", "Commission"];
    const doCurrencyFieldsExist = currencyFields.some((v) => {
        return selectedColumns.some((column) =>
            column.toLowerCase().includes(v.toLowerCase())
        );
    });
    if (!exportColumns.includes("Currency") && doCurrencyFieldsExist) {
        exportColumns.push("Currency");
    }
    let exportModelKeys = reportTemplate.columnDefinitions.map((o) => {
        if (exportColumns.includes(o.name)) {
            return {
                data: o.data,
                name: o.name,
                transformHTML: o.transformHTML,
                transformReport: o.transformReport,
            };
        } else {
            return null;
        }
    });
    exportModelKeys = exportModelKeys.filter((element) => element !== null);
    if (reportData.length < 500) {
        if (reportTemplate.templateData.templateID === 43) {
            let res = await BaseService.post(
                `v1/reports/adhoc/exportAgentsReport`,
                {
                    reportTemplate: reportTemplate,
                    selectedColumns: selectedColumns,
                    type: "blob",
                }
            );

            if (res.status === 200) {
                // Create a URL for the blob
                const blobUrl = URL.createObjectURL(res.data);
                let currentDate = moment().format("DD_MM_YYYY");
                let title = `${reportTemplate.templateData.templateTitle}_${currentDate}.xlsx`;
                // Create a temporary link element to trigger the download
                const link = document.createElement("a");
                link.href = blobUrl;
                link.download = title;
                document.body.appendChild(link);
                link.click();
            } else {
                console.log(res);
            }
            return;
        }
        const reportResult = await getReportData(reportTemplate.templateData, {
            currentPage: -1,
            pageSize: -1,
        });
        reportDataModel = reportResult.data;
    } else {
        reportDataModel = Object.assign(reportData);
    }

    const sortedModel = reportDataModel.map((o) => {
        if (currency === "dollar") {
            reportingCurrencyIso = "RXD";
        } else if (currency === "euro") {
            reportingCurrencyIso = "EUR";
        } else {
            if (o.reportingcurrencyiso) {
                reportingCurrencyIso = o.reportingcurrencyiso;
            } else {
                reportingCurrencyIso = o.reporting_currency_iso;
            }
        }
        const orderedObj = exportModelKeys.reduce((orderedObj, key) => {
            orderedObj[key.data] = key.transformReport
                ? key.transformReport(o[key.data], o["reportingcurrencyiso"])
                : key.transformHTML
                ? key.transformHTML(o[key.data])
                : o[key.data];
            return orderedObj;
        }, {});
        if (doCurrencyFieldsExist) {
            orderedObj.currency = reportingCurrencyIso;
        }
        return orderedObj;
    });
    const worksheet = XLSX.utils.json_to_sheet(sortedModel);
    const workbook = XLSX.utils.book_new();
    let currentDate = moment().format("DD_MM_YYYY");
    let title;
    if (reportTemplate.templateData.templateID === 47) {
        title = `${reportTemplate.templateData.title}.xlsx`;
    } else {
        title = `${reportTemplate.templateData.templateTitle}_${currentDate}.xlsx`;
    }
    XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        `${truncateString(reportTemplate.templateData.title, 31)}`
    );

    const rowColumns = selectedColumns.map((o) => {
        return {
            v: o,
            t: "s",
            s: columnHeaderStyle,
        };
    });

    XLSX.utils.sheet_add_aoa(worksheet, [rowColumns], { origin: "A1" });

    worksheet["!cols"] = fitToColumn(sortedModel.slice(0, 10), selectedColumns);

    const range = XLSX.utils.decode_range(worksheet["!ref"]);
    for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
            const cellAddress = XLSX.utils.encode_cell({
                r: rowNum,
                c: colNum,
            });
            const cell = worksheet[cellAddress];

            if (cell && cell.t === "n") {
                cell.z = "#,##0.00";
            }
            if (cell && cell.t === "s" && isDateFormat(cell.v)) {
                cell.t = "d";
                cell.z = "dd.mm.yyyy";
                cell.v = moment(cell.v, "DD.MM.YYYY");
            }
        }
    }
    XLSX.writeFile(workbook, title, { compression: true });
    const timestamp_end = new Date().getTime();
}

export function exportToExcelMultipleSheets(reportTemplate, reportData) {
    //creating workbook
    const workbook = XLSX.utils.book_new();
    let otherChangesFlag = false;
    for (
        let index = 0;
        index <= reportTemplate.columnDefinitionsArray.length - 1;
        index++
    ) {
        if (
            reportTemplate.columnDefinitionsArray[index].find(
                (e) => e.data === "change_type"
            )
        ) {
            reportTemplate.columnDefinitionsArray[index].pop();
        }
        let selectedColumns = reportTemplate.columnDefinitionsArray[index].map(
            (col) => {
                return col.name;
            }
        );
        const exportColumns = selectedColumns;
        //taking template columns and mapping into new array with data key and transform callback
        const exportModelKeys = reportTemplate.columnDefinitionsArray[
            index
        ].map((o) => {
            return {
                data: o.data,
                transformHTML: o.transformHTML,
                transformReport: o.transformReport,
            };
        });
        let reportDataModel = Object.assign(reportData);
        //sorting new array iterating over report data model using template column order and key
        //3 option ternary is for if one of the transformHTML methods has html tags, we can add a second method that takes preference
        let sortedModel = [];
        let filteredModel = [];
        if (reportDataModel.length > 0) {
            filteredModel = reportDataModel.filter(
                (row) => row.change_type === index
            );
            filteredModel.forEach((o) => {
                if (o.change_type === 3) {
                    otherChangesFlag = true;
                }
                delete o.change_type;
            });
        }
        if (filteredModel.length > 0) {
            sortedModel = filteredModel.map((o) => {
                return exportModelKeys.reduce((orderedObj, key) => {
                    //check for currency field so we can insert the currency
                    if (
                        key.data === "gciForPeriod" ||
                        key.data === "volumeForPeriod"
                    ) {
                        orderedObj[key.data] = o[key.data] || null;
                    } else if (key.data === "subregion_name") {
                        orderedObj[key.data] =
                            (key.transformReport
                                ? key.transformReport(
                                      o[key.data],
                                      o["reportingcurrencyiso"]
                                  )
                                : key.transformHTML
                                ? key.transformHTML(
                                      o[key.data],
                                      o["regionname"]
                                  )
                                : o[key.data]) || null;
                    } else {
                        orderedObj[key.data] = key.transformReport
                            ? key.transformReport(o[key.data])
                            : key.transformHTML
                            ? key.transformHTML(o[key.data])
                            : o[key.data];
                    }
                    return orderedObj;
                }, {});
            });
        }
        if (otherChangesFlag) {
            sortedModel.forEach((o) => {
                let outcome;
                let pairs = Object.keys(o).map((key) => [key, o[key]]);
                let result = [];

                for (let i = 0; i < pairs.length - 2; i += 3) {
                    result.push(pairs.slice(i, i + 3));
                }
                result.forEach((pair) => {
                    let prevValue = pair[0][1];
                    if (pair[1][1] === prevValue) {
                        outcome = "OK";
                    } else {
                        outcome = "Check";
                    }
                    pair.push([pair[1][0] + "_outcome", outcome]);
                });
                let flat = result.flat();
                let obj = Object.fromEntries(flat);
                Object.assign(o, obj);
            });
        }
        //converting sorted array to XLSX worksheet
        //adding styles by iterating over table columns for length then creating a new array and adding wch(width) property
        //lastly adding it to each worksheet column
        const columns = [];

        if (otherChangesFlag) {
            //For each json object in worksheet, remove the last 26 items in the object and put in a new object
            // let test = placeItems(sortedModel);
            // sortedModel = JSON.parse(test);
            //console.log(JSON.parse(test));
        }
        const worksheet = XLSX.utils.json_to_sheet(sortedModel);

        Object.keys(exportColumns).forEach((_) => {
            columns.push({ wch: 40 });
        });
        worksheet["!cols"] = columns;
        const range = XLSX.utils.decode_range(worksheet["!ref"]);
        for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
            for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
                const cellAddress = XLSX.utils.encode_cell({
                    r: rowNum,
                    c: colNum,
                });
                const cell = worksheet[cellAddress];

                // Check if the cell contains a number (not a string)
                if (cell && cell.t === "n") {
                    // Apply number formatting to the cell
                    cell.z = "#,##0.00";
                }
            }
        }
        //v === each column
        //t === 'style' for reference
        //s === imported column style object
        const rowColumns = exportColumns.map((o) => {
            return {
                v: o,
                t: "s",
                s: columnHeaderStyle,
            };
        });
        //method for adding new headers instead of data references from model
        XLSX.utils.sheet_add_aoa(worksheet, [rowColumns]);

        XLSX.utils.book_append_sheet(
            workbook,
            worksheet,
            truncateString(reportTemplate.sheetTitles[index], 31)
        );
    }
    const title = `${reportTemplate.templateData.title}.xlsx`;
    XLSX.writeFile(workbook, title);
}

const columnHeaderStyle = {
    alignment: { horizontal: "center", vertical: "center" },
    border: {
        top: { style: "medium" },
        left: { style: "medium" },
        bottom: { style: "medium" },
        right: { style: "medium" },
    },
};

function fitToColumn(arrayOfObjects, columnHeaders) {
    // Extract all property keys from the objects
    const keys = arrayOfObjects.reduce((allKeys, obj) => {
        return allKeys.concat(Object.keys(obj));
    }, []);

    // Create an object to store maximum character lengths for each key
    const maxCharacterLengths = {};

    // Calculate maximum character lengths for each key
    keys.forEach((key) => {
        const maxLen = Math.max(
            ...arrayOfObjects.map((obj) =>
                obj[key] ? obj[key].toString().length : 0
            )
        );
        maxCharacterLengths[key] = maxLen;
    });

    // Calculate the maximum width based on column headers and content
    const columnWidths = keys.map((key, i) => ({
        wch: Math.max(
            maxCharacterLengths[key],
            columnHeaders[i] ? columnHeaders[i].length : 0
        ),
    }));

    return columnWidths;
}

function isDateFormat(inputString) {
    const dateFormatRegex =
        /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/;

    return dateFormatRegex.test(inputString);
}

export function exportToExcelPeriodical(
    dataSets,
    outerColumns,
    nestedColumns,
    periodFlag,
    templateData
) {
    const outerColumnHeaders = outerColumns.map((col) => col.name);

    const headers = [...outerColumnHeaders];
    const nestedHeaders = Array(outerColumnHeaders.length).fill(""); // Empty cells for the width of outer columns

    let dateKey = "transactiondate";

    if (dataSets[0][0].date) {
        dateKey = "date";
    }

    if (dataSets[0][0].statusdate) {
        dateKey = "statusdate";
    }

    if (nestedColumns.length > 0) {
        dataSets[0].forEach((monthData) => {
            let timeMetric;
            if (periodFlag === 2) {
                timeMetric = getYearString(
                    new Date(monthData[`${dateKey}.year`])
                );
            } else {
                timeMetric = reformatDateString(
                    getYearMonthString(new Date(monthData[`${dateKey}.month`]))
                );
            }
            headers.push(timeMetric); // Add month name to headers

            headers.push(...Array(nestedColumns.length - 1).fill(""));
            nestedColumns.forEach((col) => nestedHeaders.push(col.name)); // Repeat nested column names for each month
        });
    }

    const currencyFields = ["GCI", "Volumes", "Commission"];
    const doCurrencyFieldsExist = currencyFields.some((v) => {
        return nestedHeaders.some((column) =>
            column.toLowerCase().includes(v.toLowerCase())
        );
    });
    if (!headers.includes("Currency") && doCurrencyFieldsExist) {
        headers.push("Currency");
    }

    const headersStyled = headers.map((o) => {
        return {
            v: o,
            t: "s",
            s: columnHeaderStyle,
        };
    });
    const nestedHeadersStyled = nestedHeaders.map((o) => {
        return {
            v: o,
            t: "s",
            s: columnHeaderStyle,
        };
    });

    const allRows = [headersStyled, nestedHeadersStyled];
    // Populate rows based on dataSets
    dataSets.forEach((dataSet) => {
        const row = [];
        // Fill in outer columns
        outerColumns.forEach((col) => {
            let DataPoint;
            if (col.transformReport) {
                DataPoint = col.transformReport(
                    dataSet[0][col.data.toLowerCase()]
                        ? dataSet[0][col.data.toLowerCase()]
                        : dataSet[0][col.data]
                );
            } else if (col.transformHTML) {
                DataPoint = col.transformHTML(
                    dataSet[0][col.data.toLowerCase()]
                        ? dataSet[0][col.data.toLowerCase()]
                        : dataSet[0][col.data]
                );
            } else {
                DataPoint = dataSet[0][col.data.toLowerCase()];
                if (DataPoint === undefined) {
                    DataPoint = dataSet[0][col.data];
                }
            }
            row.push(DataPoint);
        });
        let reportingCurrencyIso;
        let currency = templateData.currency;
        if (currency === "dollar") {
            reportingCurrencyIso = "RXD";
        } else if (currency === "euro") {
            reportingCurrencyIso = "EUR";
        } else {
            reportingCurrencyIso = null;
        }

        // Fill in nested columns per month
        dataSet.forEach((monthData) => {
            nestedColumns.forEach((nestedCol) => {
                let nestedDataPoint;
                // if (nestedCol.transformReport) {
                //   nestedDataPoint = nestedCol.transformReport(monthData[nestedCol.data]);
                // }
                // if (nestedCol.transformHTML && !nestedCol.transformReport) {
                //   nestedDataPoint = nestedCol.transformHTML(
                //     monthData[nestedCol.data],
                //     reportingCurrencyIso ? reportingCurrencyIso : monthData.reportingcurrencyiso
                //   );
                // } else {
                nestedDataPoint = Number(monthData[nestedCol.data] || 0);
                // }
                row.push(nestedDataPoint);
            });
            if (!reportingCurrencyIso) {
                reportingCurrencyIso = monthData?.reportingcurrencyiso;
            }
        });
        if (doCurrencyFieldsExist) row.push(reportingCurrencyIso);
        allRows.push(row);
    });
    // Convert to worksheet and handle merged cells for month headers
    let worksheet = xlsx.utils.aoa_to_sheet(allRows);
    let colIdx = outerColumnHeaders.length;
    dataSets[0].forEach(() => {
        worksheet["!merges"] = (worksheet["!merges"] || []).concat({
            s: { r: 0, c: colIdx },
            e: { r: 0, c: colIdx + nestedColumns.length - 1 },
        });
        colIdx += nestedColumns.length;
    });

    // After the above merge, we proceed to merge A1:A2, B1:B2, ... for each column up to colIdx
    for (let i = 0; i < outerColumnHeaders.length; i++) {
        // Add vertical merge for each column
        worksheet["!merges"].push({
            s: { r: 0, c: i }, // Start at row 1, current column
            e: { r: 1, c: i }, // End at row 2, current column (0-indexed)
        });
    }
    // const lastIndex = headers.length - 1
    //   // Add vertical merge for each column
    //   worksheet['!merges'].push({
    //     s: { r: 0, c: lastIndex }, // Start at row 1, current column
    //     e: { r: 1, c: lastIndex }, // End at row 2, current column (0-indexed)
    //   });

    const range = XLSX.utils.decode_range(worksheet["!ref"]);
    for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
        for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
            const cellAddress = XLSX.utils.encode_cell({
                r: rowNum,
                c: colNum,
            });
            const cell = worksheet[cellAddress];

            // Check if the cell contains a number (not a string)
            if (cell && cell.t === "n") {
                // Apply number formatting to the cell
                cell.z = "#,##0.00";
            }
        }
    }
    const colWidths = computeColumnWidths(allRows);
    worksheet["!cols"] = colWidths.map((w) => ({ wch: w }));

    // Create workbook and save to file
    let workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, "Report");
    let currentDate = moment().format("DD_MM_YYYY");
    const title = `${templateData.templateTitle}_${currentDate}.xlsx`;
    xlsx.writeFile(workbook, title, { cellStyles: true });
}

function computeColumnWidths(data) {
    return data[0].map((_, colIndex) => {
        // Get the maximum length of content in each column
        return Math.max(
            ...data.map((row) => {
                const cellValue = row[colIndex];
                return cellValue ? cellValue.toString().length : 0;
            })
        );
    });
}

function reformatDateString(input) {
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    // Split the input string into year and month
    let [year, month] = input.split("-");

    // Convert the month part to an integer and get the corresponding month name
    // Note that we subtract 1 because the array is 0-indexed but months in your input are 1-indexed
    let monthName = months[parseInt(month, 10) - 1];

    // Return the formatted string
    return `${monthName} ${year}`;
}

function getYearMonthString(date) {
    // Assume 'date' is a Date object
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    return `${year}-${month}`;
}

function getYearString(date) {
    // Assume 'date' is a Date object
    const year = date.getFullYear();
    return `${year}`;
}

function truncateString(str, charLngth) {
    if (str.length > charLngth) {
        return str.substring(0, charLngth);
    } else {
        return str;
    }
}
